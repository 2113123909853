:root{
    
    --bs-primary: #449bff !important;
    --bs-primary-rgb: 68, 155, 255;
    
    /* --bs-primary: #387fe9 !important; */
    /* --bs-warning: #f4791f !important; */
    --bs-warning: #ff862f !important;
    --bs-warning-rgb: 255, 134, 47 !important;
   
    
    /* --bs-secondary: #c4dcff !important;
    --bs-secondary-rgb: 176, 208, 255; */
} 

.btn{
    --bs-btn-disabled-opacity: 0.45 !important;
    border-radius: var(--bs-border-radius)!important;
}

.btn-primary2{
    /* --bs-btn-color: #1984ff !important; */
    --bs-btn-color: #0077ff !important;
    --bs-btn-bg: #c8e1ff !important;
    --bs-btn-border-color: #c8e1ff !important;

    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--bs-primary);
    --bs-btn-hover-border-color: var(--bs-primary);
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
}



/* .btn::before{
    content: " ";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #fff;
    border-radius: inherit;
} */

.btn-primary {
      /* --bs-btn-color: #fff; */
      --bs-btn-bg: var(--bs-primary) !important;
      --bs-btn-border-color: var(--bs-primary) !important;
      /* --bs-btn-hover-color: #fff; */
      --bs-btn-hover-bg: #0d6efd !important;
      --bs-btn-hover-border-color: #0d6efd !important;
      /* --bs-btn-focus-shadow-rgb: 49,132,253; */
      /* --bs-btn-active-color: #fff; */
      /* --bs-btn-active-border-color: #0066ff !important; */
      /* --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); */
      /* --bs-btn-disabled-color: #fff; */

   /*  --bs-btn-disabled-bg: rgba(var(--bs-primary-rgb), var(--bs-btn-disabled-opacity)) !important; 
    --bs-btn-disabled-border-color: rgba(var(--bs-primary-rgb), var(--bs-btn-disabled-opacity)) !important;  */
}

.btn-warning {
    --bs-btn-color: #ffffff !important;
    --bs-btn-bg: var(--bs-warning) !important;
    --bs-btn-border-color: var(--bs-warning) !important;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #ffca2c;
    --bs-btn-hover-border-color: #ffc720;
    --bs-btn-focus-shadow-rgb: 217,164,6;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #ffcd39;
    --bs-btn-active-border-color: #ffc720;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: var(--bs-warning) !important;
    --bs-btn-disabled-border-color: var(--bs-warning) !important;
}




