@font-face {
  font-family: 'FredokaOne';
  src: url('./fonts/FredokaOne-Regular.ttf');
}

/* .offcanvas{
  --bs-offcanvas-width: auto !important;
} */

html {
  scroll-behavior: smooth;
  --bs-body-bg: #f3f4f6 !important;
  --progress-circle-color: #ff8c00;
  --bs-warning-rgb: 255, 148, 0 !important;
  --bs-info-rgb: 6, 160, 153 !important;
  --bs-info: #06a099 !important;
  --bs-success-rgb: 49, 172, 105 !important;
  --bs-success: #31ac69 !important;

  /* WARNINGS */
  --bs-warning: #ff8c00 !important;
  --bs-warning-rgb: 255, 140, 0 !important;

  /* PRIMARY */
  /* --bs-primary: #156ff1 !important;
  --bs-primary-rgb: 21, 111, 241 !important; */
}

.main *,
.header .container,
.header .container-fluid,
.layout>main * {
  transition: all 0.2s ease-in-out !important;
  text-decoration: none !important;
}

body {
  transition: all 0s linear;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;

  /* padding-right: 0px !important; */
  overflow: hidden auto;
  /* overflow: hidden; */
}

body>#root {
  min-height: inherit;
  height: inherit;
  /* overflow-y: auto; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hover-scale-up {
  cursor: pointer;
  transition: all 0.15s linear;
}

.hover-scale-up:hover {
  z-index: 1;
  transform: scale(1.02);
}

.hover-scale-up:hover.hover-shadow-sm {
  box-shadow: 0px 2px 12px -3px #26262628;
}

*[disabled]:not([type="radio"]):not([type="checkbox"]):not([type="button"]),
.form-control.disabled {
  border: none !important;
}

@media screen and (min-width: 400px) {
  .custom-scrollbar::-webkit-scrollbar {
    width: 9px;
    background-color: #a9a9a912;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 2px;
    background-color: darkgrey;
  }
}
/* @media screen and (min-width: 991px) {
 .offcanvas{
  max-width: 80vw !important;
 }
} */
.cursor-pointer {
  cursor: pointer;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  row-gap: 3rem;
  /* padding-bottom: 2rem; */
}

/* .layout {
  overflow-y: auto;
} */

.layout>main.main {
  flex-grow: 1;
  /* display: grid; */
}

.form-field-wrapper>label.form-label {
  font-weight: 500;
  font-size: .910em;
}

.btn-group>*:first-child {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.btn-group>*:nth-child(2) {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.radius-15 {
  border-radius: 15px !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.shadow-1 {
  box-shadow: 0px 10px 15px -3px #8181810f !important;
}

.shadow-2 {
  box-shadow: 0px 10px 15px -3px #8181811a !important;
}

.shadow-3 {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
}

@media (min-width: 1400px) {
  .container-xxxl {
    max-width: 1650px;
  }
}

/* .accordion-button *{
  color: var(--bs-gray);
} */




.show-overflow-on-hover {
  overflow: hidden !important;
}

.show-overflow-on-hover:hover {
  overflow-y: overlay !important;
}

.accordion-body-px-0 {
  --bs-accordion-body-padding-x: 0px;
}

.accordion-body-py-0 {
  --bs-accordion-body-padding-y: 0px;
}

div.event-recordatory-item {
  display: grid;
  align-items: center;
  margin-bottom: 0px !important;
  column-gap: 1rem;
}

.event-recordatory-item>label {
  order: 2;
  margin-bottom: 0px;
}

.event-recordatory-item>div {
  order: 1;
}

.event-recordatory-item>small {
  margin-top: 0px;
  order: 3;
  grid-column: 1 / span 2;
  grid-row: 2;
}

.event-recordatory-item+div {
  margin-top: .5rem !important;
  margin-bottom: 0px !important;
  opacity: 0.7;
}

.field-list {
  margin-bottom: 1.5rem;
}

.field-list+div {
  opacity: 0.9;
}

.card-header {
  border-top-left-radius: inherit !important;
  border-top-right-radius: inherit !important;
}

@media print {
  body {
    background: #fff !important;
    overflow-y: auto !important;
  }

  body>#root {
    overflow-y: initial !important;
  }

  body * {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  button,button[type="submit"],
  input[type="button"],
  input[type="submit"] {
    display: none !important;
  }

  *[class*="shadow"]{
    box-shadow: 0px 0px 1px #71717126 !important;
  }

}
