.header {
  background: linear-gradient(to right, #06A099 0%, #31AC69 100%);
}

.header .brand{
  z-index: 0;
  top: -3px;
  text-align: center;
  margin: auto;
  width: 100%;
  position: absolute;
}

.header .navigation-items{
  z-index: 1;
}
.header .navigation-items a {
  position: relative;
  text-decoration: none;
}

.header .navigation-items a small, .header .navigation-items a svg{
  opacity: 0.7;
}

.header .navigation-items .active *{
  opacity: 1 !important;
}

.header .navigation-items .active::after{
  position: absolute;
  content: " ";
  width: 100%;
  background-color: #f3f4f6;
  height: 4px;
  bottom: -0.5rem;
  border-radius: 20px;
}

.title{
  font-family: 'FredokaOne', sans-serif;
  font-size: 30px;
}

.avatar>div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 42px;
  border-radius: 100%;
  background: white;
  color: black;
  text-transform: capitalize;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s box-shadow ease-in;
  overflow: hidden;
}

.avatar>div:hover {
  box-shadow: 0px 2px 15px #0000002a;
  /* opacity: 0.9; */
  border: 2px solid rgb(168, 168, 168)
}

.menu .logout-item {
  pointer-events: initial !important;
}

.avatar>div img {
  height: inherit;
  width: inherit;
  border: 2px solid #fff;
  border-radius: inherit;
}

@media print {
  .header .navigation-items{
    display: none !important;
  }
  .header .brand{
    position: initial;
  }

}